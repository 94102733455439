<template>
    <section class="big-content section-safe">
        <div class="block-columns">
            <div class="block-columns__col-title">
                <h2 class="section__title section-safe__title">
                    <span class="text-accent">{{ $t('Safety') }}</span>
                    {{ $t('your data this is our priority') }}
                </h2>
            </div>

            <p class="big-content__text section__text section__text_color_grey">
                {{ $t('We know how important it is to keep your data safe. DexNode encrypts your data, splits it into parts and distributes it to other DexNodes in the DexNet network. This means that even if an attacker is able to gain access to the device, they will only see meaningless fragments.') }}
            </p>
        </div>

        <div class="big-content__animation-block">
            <canvas ref="canvas"></canvas>
        </div>
    </section>
</template>
<script>
import * as rive from "@rive-app/canvas";

export default {
    name: 'LndSafety',
    methods: {
        setCanvasSize() {
            const maxWidth = 1328;
            const maxHeight = 450;

            const canvas = this.$refs.canvas;
            const canvasContainer = this.$refs.canvas.parentElement;
            const containerWidth = canvasContainer.clientWidth;

            let canvasWidth = 0;
            let canvasHeight = 0;

            if (containerWidth > maxWidth) {
                canvasWidth = maxWidth;
                canvasHeight = maxHeight;
            } else {
                canvasWidth = containerWidth;
                canvasHeight = containerWidth / maxWidth * maxHeight;
            }

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            return {
                width: canvasWidth,
                height: canvasHeight
            };
        },
    },
    mounted() {
        this.setCanvasSize();

        setTimeout(() => {
            const r = new rive.Rive({
                src: '/dexlink.riv', // host your Rive file and add the url to src
                canvas: this.$refs.canvas,
                autoplay: true,
                onLoad: () => {
                    // r.resizeDrawingSurfaceToCanvas();
                },
                layout: new rive.Layout({ fit: 'fitWidth', alignment: 'center' }),
            })
        }, 500)
    }
}
</script>