<template>
    <section class="block-content">
        <h2 class="block-content__title block-content__title_break_all">
            <span class="block-content__title-accent block-content__title-accent_inline">{{ $t('Performance') }}</span>
            {{ $t('beyond imagination') }}
        </h2>

        <FrameAnimationBlock
            :frame-count="21"
            :get-frame-url="(i) => require(`@/assets/images/animations/animation3S/${(i).toString().padStart(2, '0')} Aniamtion 3-squashed.jpg`)"
            :max-width="874"
            :max-height="1050"
            :start-value="'17% 27%'"
            :end-value-divider="2.5"
        />

        <div class="entry-content">
            <p>{{ $t('With a powerful 4-core Rockchip RK3566 processor, DexNode ensures') }} <span class="text-accent">{{ $t('lightning fast') }}</span> {{ $t('and') }} <span class="text-accent">{{ $t('effective') }}</span> {{ $t('work.') }}</p>

            <p>{{ $t('And with 4GB of RAM, DexNode becomes a true multitasker.') }}</p>

            <button class="button button-pay" type="button" @click="$emit('buy')">
                <span>
                    {{ $t('Buy for 145 USDT') }}
                </span>
            </button>
        </div>
    </section>
</template>
<script>
import FrameAnimationBlock from './children/FrameAnimationBlock.vue';

export default {
    name: 'LndPerformance',
    components: {
        FrameAnimationBlock,
    },
}
</script>